@import ../../styles/helpers

.head
    display: flex
    +m
        display: block

.details
    margin-right: auto

.user
    +body-bold-1

.email
    font-weight: 500
    color: $n4

.level
    display: inline-block
    margin-top: 20px
    padding: 8px 16px
    border-radius: 16px
    box-shadow: inset 0 0 0 2px $n6
    +button-2
    color: $p4
    +m
        margin-top: 12px
    +dark
        box-shadow: inset 0 0 0 2px $n3

.dropdown
    flex-shrink: 0
    width: 200px
    margin: 12px 0 0 36px
    +m
        width: 100%
        margin: 40px 0 0

.dropdownHead
    background: $n7
    box-shadow: inset 0 0 0 2px $n7
    border-radius: 24px
    +dark
        background: $n2
        box-shadow: inset 0 0 0 2px $n2

.settings
    &:not(:first-child)
        margin-top: 48px
        +m
            margin-top: 24px

.title
    margin-bottom: 40px
    +body-bold-1

.box
    margin: 10px 10px
    &:not(:last-child)
        margin-bottom: 40px

.subtitle
    margin-bottom: 5px
    padding-bottom: 5px
    border-bottom: 1px solid $n6
    +hairline-2
    color: $n5
    +dark
        border-color: $n3

.item
    display: flex
    align-items: center
    font-weight: 500
    &:not(:last-child)
        margin-bottom: 24px

.category
    margin-right: auto

.content
    margin-left: 16px
    text-align: right
    color: $n4

.btns
    margin-top: 48px
    text-align: right
    +m
        margin-top: 24px
        text-align: left

.button
    margin-left: 5px

.customFields
    display: inline-flex
    justify-content: space-evenly

.btnClass
    margin-left: 3px
    +dark
        background: $n4
        border-color: $n2
        +t
            border-color: $n3

.row
    display: flex
    padding: 10px 0 10px

.col
    flex: 1
    padding: 3px 
    +caption-bold-2
    color: $n3
    +dark
        color: $n6

.currency
    display: flex
    .text
        font-weight: 400

.icon
    flex-shrink: 0
    width: 32px
    margin-right: 20px
    img
        width: 100%

.info
    font-weight: 500
    font-size: 12px

.text
    font-weight: 500
    font-size: 11px
    color: $n4

.customSubtitle
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $n6
    +hairline-2
    color: $n5
    +dark
        border-color: $n3

.container
    width: 100%
    display: flex
    flex-wrap: wrap

.couponCard 
    width: 48%
    border-radius: 8px
    overflow: hidden
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1)
    background: #22767d
    color: #fff
    text-align: center
    margin-right: 10px
    margin-bottom: 10px

.couponHeader 
    background: #22767d
    padding: 15px

.couponHeader h3 
    margin: 0
    font-size: 36px
    letter-spacing: 2px
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)

.couponContent 
    padding: 20px

.couponContent p 
    font-size: 18px
    line-height: 1.6

.couponFooter 
    background: lightgreen
    padding: 10px
    color: black
    font-weight: 700

.couponFooter span 
    font-size: 14px

.flexChildButton
    background: #FF6838
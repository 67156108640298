@import ../../../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 280px
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    margin: 32px -8px 0
    text-align: center

.button
    width: calc(50% - 16px)
    margin: 0 8px

.buttoncontainer 
    display: flex        
    justify-content: center
    gap: 10px

.buttonClass
    padding: 10px 20px
    border: none
    border-radius: 5px
    background-color: $n4
    color: #fff
    cursor: pointer
    font-size: 16px
    &:hover 
        background-color: #22767d
    &.active
        background: #22767d
        color: #fff
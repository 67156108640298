@import ../../styles/helpers

.text
    margin-bottom: 32px
    +body-2
    color: $n4
    span
        color: $p4
    +t
        max-width: 300px

.notification
    background-color: #22767d
    color: #fff
    padding: 10px
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 5px
    margin-bottom: 10px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    font-size: 10px

.closeButton 
    background: none
    border: none
    cursor: pointer
    font-size: inherit
    color: inherit


